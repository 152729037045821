<template>
  <div>
    <Transition name="slideright-outfade">
      <section id="game" class="section px-0" :class="$parent.questionStateClass"  v-if="!$parent.ShowVideoLoop && $parent.currQuestionIndex != -1 && $parent.serverState < 3 && $parent.currQuestion != undefined">
        <div class="columns" style="padding-top: 100px;">
          <div class="column is-2">
            <!-- empty space -->
          </div>
          <!-- main -->
          <div class="column is-8 px-5">
            <div class="main mx-5 has-text-black-bis">

              <section class="section promptContainer">
                <div class="text">
                  <h2 v-if="$parent.questionState == 0" class="title is-2">{{ $t('exp_trivia.getReady') }}</h2>
                  <div v-if="$parent.questionState != 0">
                    <div class="is-size-3 pb-2 is-uppercase">
                      {{ $t('exp_trivia.questionNumber',[$parent.currQuestionIndex + 1,$parent.selectedQuestions.length]) }}
                    </div>
                    <div class="question-text-wrapper">
                      <div class="question-text"
                        :class="questionFontSize">{{ $parent.currQuestion.prompt }}</div>
                    </div>
                  </div>
                  <progress-circle
                    :class="{ active: $parent.questionState > 1 && $parent.questionState < 3 }"
                    :progress="1 - $parent.clockNormalized"
                    :diameter="120"
                    :border="14"
                    progress-color="#ccc"
                    track-color="var(--primary)"
                    inner-color="#fff">
                    <span class="is-size-3 has-text-weight-bold">
                      {{ $parent.clockDisplay }}
                    </span>
                  </progress-circle>
                  
                </div>
                <div v-if="$parent.currQuestion.image" class="image">
                  <img :src="$parent.currQuestion.image.sizes.high.url || $parent.currQuestion.image.url"
                    :key="$parent.currQuestion.id + '_img'" />
                </div>
              </section>
              <!-- Options -->
              <div id="options" v-if="$parent.questionState > 1">
                <transition-group appear @before-enter="beforeEnter" @enter="enter" @leave="leave">
                  <div v-for="(answer, i) in $parent.currQuestion.options" :key="i"
                    class="option is-flex is-align-items-center"
                    :class="{ correctAnswer: answer.id == $parent.currQuestion.id && $parent.questionState == 3 }"
                    :data-index="i">
                    <div class="letter">
                      <div class="has-background-white-bis has-text-weight-bold">
                        {{ $parent.intToChar(i) }}
                      </div>
                    </div>
                    <div class="text is-flex is-align-items-center px-5">
                      <div class="mx-5">
                        {{ answer.option }}
                      </div>
                    </div>
                    
                      <!-- Caritas de los que contestaron -->
                      <div v-if="!$parent.options.config.hideGuessesUntilReveal || $parent.questionState == 3" class="faces mr-5">
                        <div v-for="player in optionGuessed(answer).slice(0,3)" :key="player.dbid" class="ml-1">
                          <player-avatar :player="player" :scale="3" :showUsername="false" />
                        </div>
                        <div v-if="optionGuessed(answer).length > 3" class="more-users ml-2 has-text-weight-bold">
                          +{{optionGuessed(answer).length - 3 }}
                        </div>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
          <!-- soreboard -->
          <div class="column is-2">
            <div class="gamescore">
              <div class="header">{{ $t('exp_trivia.score') }}</div>
              <TransitionGroup name="list" class="scoreboard" tag="ul">
                <div v-for="player in $parent.playersScoreboard.slice(0, maxVisibleUsers)" class="item" :key="player.dbid">
                  <player-avatar :player="player" :scale="2" :showUsername="false" />
                  <span class="username">{{ player.username }}</span>
                  <span >
                    <animated-number :number="Math.floor(player.score)" :duration=".7" />
                  </span>
                </div>
              </TransitionGroup>
              <div v-if="$parent.playersScoreboard.length > maxVisibleUsers" class="item-extra-users" > 
               + {{ $tc('exp_trivia.otherUsers',$parent.playersScoreboard.length - maxVisibleUsers) }}
                
              </div>
            </div>
          </div>
        </div>

      </section>
    </Transition>
    <Transition name="slidebottom">
      <section class="section has-text-black-bis" v-if="$parent.serverState == 3" id="finalScore">
        <!-- Final Screen -->
        <template v-if="$parent.playersScoreboard.length == 1">
            <!-- Un solo jugador -->
            <div class="final scoreboard tie">
              <img src="/assets/trivia/great.svg" class="pt-5 pb-3">
              <div class="big-title">{{ $t('exp_trivia.great') }}</div>
              <div class="description">{{ $t('exp_trivia.youCompletedTheTrivia') }}</div>
              <div class="others">
                <div class="info item">
                  <player-avatar :player="$parent.playersScoreboard[0]" :scale="2" :showUsername="false" />
                  <span class="username">{{ $parent.playersScoreboard[0].username }}</span>
                  <span class="score">{{ Math.floor($parent.playersScoreboard[0].score) }}</span>
                </div>
              </div>
            </div>
        </template>
        <template v-else-if="$parent.playersScoreboard[0].score > 0">
          <!-- Podio -->
          <div class="final scoreboard">
            <div class="podium">
              <div class="second">
                <div v-if="$parent.playersScoreboard.length > 1" class="user">
                  <player-avatar :player="$parent.playersScoreboard[1]" :scale="4" :showUsername="false" />
                  <div class="username">{{ $parent.playersScoreboard[1].username }}</div>
                  <div class="score">{{ Math.floor($parent.playersScoreboard[1].score) }}</div>
                </div>
                <div class="stand">{{ $t('exp_trivia.ordinal_2') }}</div>
              </div>
              <div class="first">
                <div v-if="$parent.playersScoreboard.length > 0" class="user has-text-weight-bold">
                  <player-avatar :player="$parent.playersScoreboard[0]" :scale="5" :showUsername="false" />
                  <div class="username">{{ $parent.playersScoreboard[0].username }}</div>
                  <div class="score">{{ Math.floor($parent.playersScoreboard[0].score) }}</div>
                </div>
                <div class="stand">{{ $t('exp_trivia.ordinal_1') }}</div>
              </div>
              <div class="third">
                <div v-if="$parent.playersScoreboard.length > 2" class="user">
                  <player-avatar :player="$parent.playersScoreboard[2]" :scale="4" :showUsername="false" />
                  <div class="username">{{ $parent.playersScoreboard[2].username }}</div>
                  <div class="score">{{ Math.floor($parent.playersScoreboard[2].score) }}</div>
                </div>
                <div class="stand">{{ $t('exp_trivia.ordinal_3') }}</div>
              </div>
            </div>
            <div class="others mt-3">
              <div v-for="(player, index) in $parent.playersScoreboard.slice(3,8)" :key="player.dbid" class="item">
                <div class="rank">{{ $t('exp_trivia.ordinal_n',[index + 4]) }}</div>
                <player-avatar :player="player" :scale="2" :showUsername="false" />
                <span class="username">{{ player.username }}</span>
                <span class="score">{{ Math.floor(player.score) }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- Empate -->
          <div class="final scoreboard tie">
            <img src="/assets/trivia/tie.svg" class="pt-5 pb-3">
            <div class="big-title">{{ $t('exp_trivia.deadHeat') }}</div>
            <div class="description">{{ $t('exp_trivia.noPlayerWon') }}</div>
          </div>
        </template>
        <canvas id="confetti-canvas"></canvas>
      </section>
    </Transition>
  </div>
</template>

<script>
import gsap from "gsap";
import AnimatedNumber from "../AnimatedNumber.vue";
import ProgressCircle from '../ProgressCircle.vue';
import PlayerAvatar from '../PlayerAvatar.vue';
import "./screenTriviaDefaultGame.scss";

export default {
  components: {
    AnimatedNumber,
    ProgressCircle,
    PlayerAvatar
  },
  data() {
    return {
      maxVisibleUsers : 10,
    };
  },

  methods: {
    // Animaciones
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-100px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.3
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        x: -20,
        duration: 0.2,
        onComplete: done,
      });
    },
    optionGuessed(answer){
      return this.$parent.currQuestion.options.find(x=>x.id == answer.id).guessed
    }
  },
  computed: {
    questionFontSize() {

      const len = this.$parent.currQuestion.prompt.length
      if (len < 64) return 'is-size-1'
      if (len > 80) return 'is-size-3'
      return 'is-size-2'

    }
  }
};
</script>